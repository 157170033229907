import { isCustomCreativeBuilderTemplate, isTemplateDesignerTemplate } from './isTemplate';
import { TemplateAsset } from '../types/template-management.type';

const getTemplateFormatsCount = (template: TemplateAsset): number => {
    if (isTemplateDesignerTemplate(template)) return template.data.templateSetup?.formats?.length || 0;
    if (isCustomCreativeBuilderTemplate(template)) return template.data.settings?.formats?.length || 0;
    return 0;
};

export { getTemplateFormatsCount };
