import {
    isCustomTemplate,
    isTemplateDesignerTemplate,
    isCreativeBuilderTemplate,
    isCustomTemplateType,
    isTemplateDesignerTemplateType,
    isCreativeBuilderTemplateType,
    isCustomCreativeBuilderTemplate
} from './isTemplate';
import { getTemplatePreviewImage } from './getTemplatePreviewImage';
import { isAMV2Enabled } from './isAmV2Enabled';
import { getTemplateIdentifier } from './getTemplateIdentifier';
import { getTemplateFormatsCount } from './getTemplateFormatsCount';
import { getTDTemplateFramesCount } from './getTDTemplateFramesCount';

export {
    getTemplatePreviewImage,
    isAMV2Enabled,
    isTemplateDesignerTemplate,
    isCustomTemplate,
    isCreativeBuilderTemplate,
    isCustomCreativeBuilderTemplate,
    isTemplateDesignerTemplateType,
    isCustomTemplateType,
    isCreativeBuilderTemplateType,
    getTemplateIdentifier,
    getTemplateFormatsCount,
    getTDTemplateFramesCount
};
