import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import TruncatedTextWithTooltip from 'components/ui-components-cape/TurncatedTextWithTooltip';
import Typography from 'components/ui-components-v2/Typography';

import '../styles/info.scss';

interface Props {
    title: string;
    subtitle: string;
    small?: boolean;
    icon?: string;
}

const TemplateManagementAssetTileInfo = ({ title, subtitle, icon = 'design_services', small }: Props) => {
    return (
        <div className="template-management-asset-tile-info">
            <Icon color="disabled">{icon}</Icon>
            <div className="template-management-asset-tile-info__text">
                <TruncatedTextWithTooltip variant={small ? 'h5' : 'h4'}>{title}</TruncatedTextWithTooltip>
                <Typography variant="body2" color="textSecondary" noWrap className="template-management-asset-tile-info__subtitle">
                    {subtitle}
                </Typography>
            </div>
        </div>
    );
};

export default TemplateManagementAssetTileInfo;
